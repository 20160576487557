import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import StorybookEmbed from "../../../components/StorybookEmbed.jsx";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl="#" codeUrl="#" checklists={checklists.componentSlider.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`A slider component is a user interface element that enables users to select a value from a continuous range by dragging a handle along a track.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/slider/android-compose-slider.gif",
            "alt": "modal Legion android compose"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Example`}</h2>
    <h3>{`Slider with Value`}</h3>
    <h4>{`With Step 0`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    val sliderPosition = remember { mutableStateOf(20f) }

    val min = 0f
    val max = 100f

    LgnSlider(
        modifier = Modifier.width(300.dp),
        value = sliderPosition.value,
        valueRange = min..max,
        onValueChange = {
            sliderPosition.value = it
        },
        steps =  0
    )
`}</code></pre>
    <h4>{`With Step 9`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    val sliderPosition = remember { mutableStateOf(20f) }

    val min = 0f
    val max = 100f

    LgnSlider(
        modifier = Modifier.width(300.dp),
        value = sliderPosition.value,
        valueRange = min..max,
        onValueChange = {
            sliderPosition.value = it
        },
        steps =  9
    )
`}</code></pre>
    <h4>{`With Step 4`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    val sliderPosition = remember { mutableStateOf(20f) }

    val min = 0f
    val max = 100f

    LgnSlider(
        modifier = Modifier.width(300.dp),
        value = sliderPosition.value,
        valueRange = min..max,
        onValueChange = {
            sliderPosition.value = it
        },
        steps =  4
    )
`}</code></pre>
    <h4>{`With Step 19`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    val sliderPosition = remember { mutableStateOf(20f) }

    val min = 0f
    val max = 100f

    LgnSlider(
        modifier = Modifier.width(300.dp),
        value = sliderPosition.value,
        valueRange = min..max,
        onValueChange = {
            sliderPosition.value = it
        },
        steps =  19
    )
`}</code></pre>
    <h3>{`Disabled`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    val sliderPosition = remember { mutableStateOf(20f) }

    val min = 0f
    val max = 100f

    LgnSlider(
        modifier = Modifier.width(300.dp),
        value = sliderPosition.value,
        valueRange = min..max,
        onValueChange = {
            sliderPosition.value = it
        },
        steps =  19,
        enabled = true
    )
`}</code></pre>
    <h3>{`Range Slider`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    var sliderPosition by remember { mutableStateOf(0f..100f) }

    LgnRangeSlider(
        modifier = Modifier.width(300.dp),
        value = sliderPosition,
        steps = 9,
        valueRange = 0f..100f,
        onValueChange = {
            sliderPosition = it
        }
    )
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Current value of the slider`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onValueChange`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Function`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback in which value should be updated`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`valueRange`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Range of values that this slider can take`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`steps`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`if greater than 0, specifies the amount of discrete allowable values, evenly distributed across the whole value range.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`enabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Controls the enabled state of this slider`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onValueChangeFinished`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Function`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Called when value change has ended`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnSliderColors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[LgnSliderColors]`}{` that will be used to resolve the colors used for this slider in different states. See `}{`[LgnSliderDefaults.colors]`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Interaction`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MutableInteractionSource`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[MutableInteractionSource]`}{` representing the stream of `}{`[Interaction]`}{`s for this slider`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`modifier`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Modifier`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Modifiers for the Range Slider layout`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      